*,
*::before,
*::after {
  user-select: none;
}

html {
  scrollbar-gutter: stable both-edges;
}

body {
  margin: 0;
  min-height: 100vh;
}

.btn > svg {
  display: inline-block;
  vertical-align: top;
  height: 21px;
}

.App {
  padding: 0.5rem;
  max-width: 500px;
  margin: 0 auto;
}

.TapBar {
  margin: -0.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.5rem;
  display: flex;
  gap: 0.5rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
}

.App:has(.tabs + .section) .tabs {
  display: none;
}

.firebase-emulator-warning {
  padding: 0.5rem;
  font-size: 0.8rem;
  pointer-events: none;
  opacity: 0.5;
  background-color: var(--bs-danger) !important;
  inset: 0.5rem !important;
  top: unset !important;
  width: unset !important;
  color: white !important;
  border: none !important;
}
